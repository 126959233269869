.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 2rem;
}

.emoji {
    font-size: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
}

.inputs {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.input {
    flex: 1;
}

.fullWidthInput {
    width: 100%;
}

.submitButton {
    background-color: #f06292;
    color: white;
}
